.tooltip .tooltiptext {
    visibility: hidden;
    width: 160px;
    background-color: #ffffff;
    color: rgb(201, 201, 202);
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    margin-top: 3px;
    margin-left: 20px;
    border-color: rgb(226, 226, 226);
    /* border-width: 0.1px;
    border-style: solid; */
    box-shadow: 2px 2px 2px;
  }
  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    /* margin-left: -5px; */
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent rgba(17, 20, 45, 1) transparent;
    margin-left: 25px;
  }
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
  
  .custom-color {
    background-color: #ffffff;
  }
  